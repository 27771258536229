.info-btn {
    color: white;
    background-color: rgb(204, 25, 25);
    position: absolute;
    width: 20px;
    height:20px;
    border-radius: 100%;
    opacity: .3;
    z-index: 104;
    font-size: 13px;
}

.info-btn:hover {
    opacity: 1;
    cursor: pointer;
}