.homePage {
    margin: 0;
    border: 0;
    padding: 0;
    background-color: #46acb9;
    background-image: url("../media/Background3.png"), url("../media/Background3_small.png");
    background-position: bottom, bottom;
    background-size: cover, cover;
    position: relative;
    /* padding-top: 5%; */
    /* height: calc(95% - 30px); */
    width: 100%;
    overflow-y: hidden;
    text-align: center;
}

.programTilesCont {
    /* background-color: rgba(63, 63, 63, 0.45); */
    aspect-ratio: 1.35/1;
    position: relative;
    padding-top: 3%;
    max-width: 100%;
    max-height: 92%;
    /* width: 100%; */
    /* position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%); */
    text-align: center;
    margin: 0 auto;
    border-radius: 10px;
    vertical-align: middle;
    animation: fadeIn 2s;
}