.tile {
    /* width: 11vw;
    height: 11vw; */
    /* aspect-ratio: 1.1/1; */
    width: 17%;
    height: 21%;
    min-width: 110px;
    max-width: 24vh;
    max-height: 24vh;
    border-radius: 30px;
    text-align: center;
    display: inline-block;
    position: relative;
    transition: all .2s;
    margin: 0 5px 0 5px;
    overflow-y: visible;
    vertical-align: middle
}

.able-tile:hover {
    scale: 1.03;
}


.able-tile:hover span {
    background-color: rgba(50,50,50, 0.6);
    padding: 7px 0;
    top: calc(var(--top) - 4%);
}

.tile img {
    position: relative;
    height: 120%;
    width: 100%;
    /* top: 50%; */
    /* object-fit:none; */
    /* object-fit: cover; */
    /* border-radius: 10px;
    border: 2px solid rgb(78, 78, 78); */
}

.tile span {
    --top: 40%;
    margin: 0 auto;
    width: calc(100% - 6px);
    transition: padding .2s;
    position: absolute;
    top: var(--top);
    left: 0;
    right: 0;
    color: white;
    font-size: 18px;
    background-color: rgba(50,50,50,0.5);
    transition: all .5s;
}

.tile-link {
    width: fit-content;
    height: fit-content;
}

@media only screen and (max-width: 1200px){
    .tile span {
        font-size: 13px;
    }
}

@media only screen and (max-width: 520px ) {

    /* .tile {
        height: 130px;
        margin: 3px;
    } */

    .tile span {
        font-size: 9px;
    }

    .tile {
        height: 21% !important;
        width: 20% !important;
    }
    
}