.map {
    max-width: 100%;
    max-height: calc(100% - 32px);
    height: 75vw;
    width: 128vh;
    position: relative;
    margin: 0 auto;
    animation: fadeIn 1s, scaleIn 1s;
    /* background-color: red; */
}

.map img {
    top: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
}