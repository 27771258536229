.App,
#root,
body,
html {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.pulsate {
  animation: pulsate infinite 2s linear;
}

.header {
  height: 30px;
  width: 100%;
  background-color: #444;
  color: white;
  text-align: left;
}

.footer-icon {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 55px;
  z-index: 105;
}

.header-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 23px;
  z-index: 105;
}

.header h4 {
  padding: 3px 0 0 0;
  margin: 0;
  margin-left: 10px;
}

.page {
  height: calc(100% - 30px);
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color:rgb(233, 233, 233);
  background-image: url('./media/bg2.png'), url('./media/bg2_small.png');
  background-position: center, center;
  background-size: cover, cover;
}

.page-title {
  margin: 0;
  font-size: 38px;
}

.back-btn {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #888;
  color: white;
  padding: 5px;
  vertical-align: top;
  border-bottom-right-radius: 10px;
  z-index: 105;
  transition: all .3s;
}

.back-btn img {
  height: 25px;
  margin-right: 5px;
  vertical-align: middle;
}
.back-btn h4 {
  display: inline-block;
  margin: 0;
  font-size: 18
}

.back-btn:hover h4 {
  text-decoration: underline;
}

.back-btn:hover {
  background-color: #555;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.close-btn {
  width: 22px;
  height: 18px;
  background-color: rgb(216, 109, 109);
  right: 4px;
  top: 4px;
  position: absolute;
  text-align: center;
  padding-bottom: 2px;
  font-size: 14px;
  padding-top: 1px;
  border-radius: 4px;
}

.close-btn:hover {
  scale: 1.02;
  cursor: pointer;
  background-color: red;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes scaleIn {
  0% {scale: 0.94;}
  100% {scale: 1.0;}
}

@keyframes fadeIn {
  0% {opacity: .3}
  100% {opacity: 1}
}

@keyframes fadeInFromZero {
  0% {opacity: 0}
  30% {opacity: 0}
  100% {opacity: 1}
}

@keyframes pulsate {
  0% {opacity: 0}
  40% {opacity: .9}
  60% {opacity: .9}
  100% {opacity: 0}
}