.popup-menu {
    display: none;
    position: absolute;
    /* left: 10px; */
    /* right: 10px; */ 
    left: 50%;
    transform: translate(-50%, 0);
    width:max-content;
    max-width: calc(100% - 40px);
    /* width: calc(100% - 20px); */
    top: 20px;
    margin: 0 auto;
    background-image: url('../media/popup_bg.webp');
    z-index: 108;
    height:max-content;
    bottom: 20px;
    max-height: calc(100% - 40px);
    height: calc(100% - 20px);
    text-align: center;
    border-radius: 8px;
    transition: all 0.5s;
    animation: fadeInFromZero .7s;
}

.popup-header {
    width: 100%;
    height: 28px;
    padding-top: 4px;
    /* background-color: rgb(0, 102, 128); */
    background-color: rgb(37, 37, 37);
    color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/* this is if entire popup contents is an img */
.popup-img-overview {
    position: relative;
    object-fit: contain;
    height: calc(100% - 32px);
    max-width: 100%;
    max-height: calc(100% - 32px);
    object-position: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}