.layer-menu {
    position: absolute;
    top: 0;
    right: 0px;
    width: 200px;
    height: 100%;
    background-color: #636363;
    color: white;
    transition: all 0.5s;
    z-index: 105;
}
.menu-item-back {
    position: absolute !important;
    width: 100%;
    bottom: 0 !important;
    background-color: #888;
    color: white;
    padding: 5px;
    vertical-align: top;
    border-bottom-right-radius: 10px;
    z-index: 105;
    transition: all .3s;
}

.menu-item-back img {
    height: 25px;
    margin-right: 5px;
    vertical-align: middle;
}

.layer-menu h4 {
    margin: 0;
    padding: 10px 0;
    background-color:rgb(46, 46, 46)
}

.expander-btn {
    left: -85px;
    top: 95px;
    transform: rotate(270deg);
    position: absolute;
    width: 120px;
    padding: 5px 10px;
    background-color: rgba(59, 88, 95, 0.637);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


.expander-btn:hover {
    background-color: rgb(0, 102, 128);
    cursor: pointer;
}

.layer-menu .menu-item {
    background-color: #636363;
    border-bottom: 1px solid black;
    position: relative;
    text-align: left;
    padding: 10px 0;
    padding-left: 15px;
    padding-right: 30px;
}

.layer-menu .menu-item:nth-child(1) {
    border-top: 1px solid black;
}

.layer-menu .menu-item:hover {
    cursor: pointer;
    background-color:rgb(0, 102, 128);
}

.icon-tick {
    position: absolute;
    right: 5px;
    /* top: 10px; */
    top: calc(50% - 10px);
    height: 20px
}
